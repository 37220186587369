import http from "./httpService";
import apiUrl from "../config.json";

async function getUserData(username) {
  if (username === null) {
    const nullReturn = { data: [] };
    return nullReturn;
  } else {
    const apiEndpoint = apiUrl.apiUrl + "/employee-applications/getUserData"
    const responseData = await http.post(apiEndpoint, { username: username })
    return responseData;
  }
}

async function saveUserData(username, title, saveData) {
  const apiEndpoint = apiUrl.apiUrl + "/employee-applications/saveUserData";
  const currentUser = getCurrentUser();
  return await http.post(apiEndpoint, { username: currentUser, [title]: saveData });
}

async function signup(email, password, firstName, lastName, mobileNumber, agencyInfo) {
  const signupURL = "https://usermgmt.mysembra.com/aws-auth/register";
  // const signupURL = apiUrl.apiUrl + "/cognito/registerUser"

  const newCognitoUser = {
    Username: email.toLowerCase(),
    password: password,
    Organization: "SembraApplicant",
    PhoneNumber: "+1" + mobileNumber.replace(/\D/g, ''),
    email: email.toLowerCase(),
    name: firstName + " " + lastName,
    canBroadcast:false,
    Role:"USER"
  }

  try {
    return await http.post(signupURL, newCognitoUser).then(async response => {
      const apiEndpoint = apiUrl.apiUrl + "/employee-applications/createApplicant";
      localStorage.setItem('applicant', email.toLowerCase());
      const newEmpInfo = {
        firstName: firstName,
        lastName: lastName,
        email: email.toLowerCase(),
        phoneNumber:mobileNumber.replace(/\D/g, '')
      }
      return await http.post(apiEndpoint, {
        username: email.toLowerCase(), agencyInfo: agencyInfo, applicantStatus: "in-progress"
        , empInfo: newEmpInfo
      });
    })
  }
  catch (error) {
    if(error.response)
      if(error.response.data)
        return error.response.data.message
    return "Error in Signup";
  };
}

function login(username, password) {
  const loginURL = "https://usermgmt.mysembra.com/aws-auth/login";
  const cognitoUser = {
    Username: username.toLowerCase(),
    Password: password,
    realm: "SembraApplicant"
  }
  try {
    return http.post(loginURL, cognitoUser).then(async res => {
      localStorage.setItem('applicant', username.toLowerCase());
      localStorage.setItem('token', res.data.idToken);
      localStorage.setItem('realm', res.data.Realm);
      localStorage.setItem('userAccessToken', res.data.accessToken);
      localStorage.setItem('userRefreshToken', res.data.refreshToken);
      return res.data;
    }).catch(async err => {
      if (err.response.data.message === "User is not confirmed.") {
        localStorage.setItem('applicant', username.toLowerCase());
        return "Login"
      } else {
        if (err.response.data.message == "User Realm not found") {
          const agencyInfo = {
            name: "Home Choice Health Care",
            address1: "4000 Blue Ridge Rd Suite 120",
            city: "Raleigh",
            state: "NC",
            zip: "27612",
            phone: "9198978500",
            fax: "9198978509",
            email: "matthew@homechoicehealthcare.net",
            agencyCode: "HCH"
          }
          const apiEndpoint = apiUrl.apiUrl + "/employee-applications/createApplicant";
          localStorage.setItem('applicant', username.toLowerCase());
          return await http.post(apiEndpoint, { username: username.toLowerCase(), agencyInfo: agencyInfo, applicantStatus: "in-progress" });
        } else
          return err.response.data.message;
      }
    })
  }
  catch {
    return "Error in Sending";
  };
}

async function verifyCognito(username, veriCode) {
    const verificationURL = "https://usermgmt.mysembra.com/aws-auth/confirmSignup";

    const newVerificationCode = {
      username:username.toLowerCase(),
      code:veriCode
    }
  
    try {
      return await http.post(verificationURL, newVerificationCode);
    }
    catch (error) {
      // console.log(error)
      return "Error in Verification";
    };
}

export function logout() {
  localStorage.clear();
  window.location = "/login"
}

export function getCurrentUser() {
  try {
    return localStorage.getItem("applicant");
  } catch (ex) {
    return null;
  }
}

export default {
  getUserData,
  saveUserData,
  login,
  signup,
  verifyCognito,
  logout,
  getCurrentUser
}